@mixin backgroundimage() {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
};
@mixin marginCenter($width) {
  max-width: #{$width}px;
  margin-left: auto;
  margin-right: auto;
};
@mixin absoluteFull() {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
};



@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');


$primary_font: 'Roboto', sans-serif;
$roboto_condensed_font: 'Roboto Condensed', sans-serif;
$playfair_font: 'Playfair Display', serif;
$primary_font_weight: 400;
$primary_color: #3F3F3F;
$white_color: #fff;
$pink_color: #E2A0C6;
$yellow_color: #F3E83C;
$transition_c: 600ms cubic-bezier(0.23, 1, 0.32, 1);
$transition: 0.5s ease;


body{line-height: 1; color: $primary_color; font-size: 15px; font-family: $primary_font}

html.no-scroll, body.no-scroll{overflow: hidden; height: 100%; }
a, a:link, a:visited, a:hover, a:active{text-decoration: none; color: $primary_color}
p{margin-bottom: 1.5rem}
p:last-child{ margin-bottom: 0;}
:focus{outline: none}


textarea, button, input[type="text"], input[type="button"], input[type="email"], input[type="submit"], input[type="tel"], input[type="number"], input[type="password"] {
  -webkit-appearance: none; border-radius: 0; outline: none;
}
h1, h2, h3, h4, h5, h6{
  font-weight: 400; margin-bottom: 0; margin-top: 0;
}
bold{
  font-weight: 700;
}


.waypoint_fadeIn{opacity: 0; transition: 1s}
.waypoint_fadeIn.active{opacity: 1;}

.waypoint_fadeIn.fadeInUp{transform: translateY(50px); }
.waypoint_fadeIn.fadeInUp.active{transform: translateY(0);}

.waypoint_fadeIn.fadeInRight{transform: translateX(50px); }
.waypoint_fadeIn.fadeInLeft{transform: translateX(-50px); }
.waypoint_fadeIn.fadeInRight.short{transform: translateX(30px); }
.waypoint_fadeIn.fadeInLeft.short{transform: translateX(-30px); }
.waypoint_fadeIn.fadeInLeft.active, .waypoint_fadeIn.fadeInRight.active{transform: translateX(0);}

.waypoint_fadeIn.fadeIn_d2{ transition-delay: 0.2s;}
.waypoint_fadeIn.fadeIn_d4{ transition-delay: 0.4s;}


.container{max-width: 1450px; padding-left: 25px; padding-right: 25px }


#scroll_top{
  bottom: 50px; right: 50px; position: fixed; width: 50px; visibility: hidden; opacity: 0; transition: $transition;
  cursor: pointer; z-index: 5;
  img{width: 100%}
  &.active{visibility: visible; opacity: 1;}
}


#header_menu{
  position: fixed; width: 100%; z-index: 10; background-color: $white_color; transition: 0.3s;
  .container{
    position: relative;
  }
  ul{
    padding-left: 0; margin-left: 0; list-style: none; margin-bottom: 0;
  }
  #site_logo{
    position: absolute; height: 72px; top: 0; bottom: 0; margin: auto; transition: 0.3s;
    img{
      height: 100%;
    }
  }
  #header_main_menu{
    font-size: 16px; padding-top: 40px; padding-bottom: 40px; margin-left: 175px; transform : translate3d(0, 0, 0); transition: 0.3s;
    .main_menu_item{
      display: inline-block; padding-left: 20px; padding-right: 20px; position: relative; padding-top: 10px; padding-bottom: 10px;
      > a{
        display: block; padding-top: 2px; padding-bottom: 2px; vertical-align: middle; font-family: $playfair_font; font-size: 16px;
        span{
          display: inline-block; vertical-align: middle; transition: 0.3s;
        }

        .arrow{
          height: 5px; display: inline-block; vertical-align: middle; padding-left: 15px;
          &.wh{
            display: none;
          }
        }
      }
      &:hover{
        >a{
          span{
            color: $pink_color;
          }
        }
      }
    }
    .main_submenu{
      position: absolute; padding-left: 20px; padding-right: 20px; visibility: hidden; opacity: 0; top: calc(100%); border: 1px solid #fff;
      left: 0; width: 200px; padding-top: 10px; padding-bottom: 10px; background-color: $pink_color; transition: 0.3s;
      .submenu_item{
        > a{
          font-family: $primary_font; font-size: 13px; padding-top: 8px; padding-bottom: 2px; margin-bottom: 6px; display: inline-block; color: $white_color;
          border-bottom: 1px solid transparent; transition: 0.3s;
          &:hover{
            border-bottom-color: $white_color;
          }
        }
      }
    }
    .main_menu_item:hover{
      .main_submenu{
        visibility: visible; opacity: 1;
      }
    }
  }
  #header_right_menu{
    position: absolute; right: 25px; right: 25px; top: 0; bottom: 0; margin: auto; height: 20px;
    .right_menu_section{
      display: inline-block; font-size: 16px; vertical-align: middle;
    }
    .lang_section{
      margin-right: 35px; display: none;
      a, span{
        display: inline-block; padding-top: 2px; padding-bottom: 2px;
      }
      a{
        padding-left: 10px; padding-right: 10px;
      }
    }
    .share_item{
      display: inline-block; margin-right: 25px; transition: 0.3s;
      &:last-child{
        margin-right: 0;
      }
      a{
        display: block; position: relative;
      }
      img{
        height: 20px;
        &.wh{
          display: none;
        }
        &.pink{
          display: inline; position: absolute; left: 0; top: 0; opacity: 0; transition: $transition;
        }
      }
      a:hover{
        .pink{
          opacity: 1;
        }
      }
    }
  }
  #responsive_btn{
    position: absolute; right: 20px; padding: 0;  top: 0; bottom: 0; margin: auto;
    -webkit-appearance: none; outline: none; z-index: 6;
    .hamburger-box{
      width: 27px; display: block;
    }
    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before{
      height: 2px; width: 27px;
    }
    .hamburger-inner:before{
      top: -9px;
    }
    .hamburger-inner:after{
      bottom: -9px;
    }
    &.is-active {
      .hamburger-inner{
        background-color: $white_color;;
      }
      .hamburger-inner:after{
        bottom: 0; background-color: $white_color;
      }
      .hamburger-inner:before{
        top: 0; background-color: $white_color;
      }
    }
  }
  #responsive_menu{
    font-size: 16px; padding-top: 130px; position: fixed; transform: translateX(100%); background-color: $pink_color; top: 0;
    height: 100vh; right: 0; transition: $transition_c; width: 100%; max-width: 375px; z-index: 5; color: $white_color;
    ul, li{
      margin-left: 0; padding-left: 0; margin-bottom: 0; list-style: none;
    }
    .main_menu_item {
      padding-left: 40px; padding-right: 20px; position: relative; margin-bottom: 50px;
      > a {
        display: inline-block; font-family: $playfair_font; font-size: 30px; color: #3F3F3F; border-bottom: 1px solid transparent;
        &:hover{
          border-bottom-color: #3F3F3F;
        }
      }
    }
    .main_submenu{
      padding-top: 12px; display: none;
      .submenu_item{
        font-size: 17px; padding-top: 6px; padding-bottom: 6px; padding-left: 20px; position: relative;
        &:after{
          content: ''; width: 10px; background-color: #3F3F3F; height: 1px; top: 0; bottom: 0; margin: auto;
          position: absolute; left: 0;
        }
        > a{
          color: #3F3F3F; border-bottom: 1px solid transparent; font-family: $primary_font;
          &:hover{
            border-bottom-color: #3F3F3F;
          }
        }
      }
    }
    .main_menu_link.active{
      & + .main_submenu{
        display: block;
      }
    }
    .bottom_menu{
      position: absolute; bottom: 50px; left: 40px; right: 40px;
      .bottom_menu_section{
        display: inline-block; font-size: 16px; vertical-align: middle;
      }
      .lang_section{
        position: absolute; right: 0; display: none;
        a, span{
          display: inline-block; padding-top: 2px; padding-bottom: 2px; color: $white_color
        }
        a{
          padding-left: 10px; padding-right: 10px;
        }
      }
      .share_item{
        display: inline-block; margin-right: 25px; transition: 0.3s;
        &:hover{
          opacity: 0.5;
        }
        &:last-child{
          margin-right: 0;
        }
        a{
          display: block;
        }
        img{
          height: 20px;
          &.wh{
            display: none;
          }
        }
      }
    }
  }
  .responsive-menu-bg{
    display: none; position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(255,255,255,0.8);
    z-index: 4;
  }
  &.is-active{
    #responsive_menu{
      transform: translateX(0);
    }
  }
  &.in_scroll{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); background-color: rgba(255,255,255,0.9);
  }
}

.transparent_header{
  .page_content{
    padding-top: 0;
  }
  #header_menu:not(.in_scroll) {
    color: $white_color; background-color: transparent;
    a{
      color: $white_color;
    }
    .main_menu_item {
      &:hover {
        > a {
          span {
            border-bottom-color: $primary_color;
          }
        }
      }
    }
    #header_main_menu{
      .main_menu_item > a {
        .arrow{
          display: none;
          &.wh{
            display: inline-block;
          }
        }
      }
    }
    #header_right_menu {
      .share_item {
        img{
          display: none;
          &.wh, &.pink{
            display: inline;
          }
          &.pink{
            position: absolute;
          }
        }
      }
    }
    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before{
      background-color: $white_color;
    }
  }
}

#footer{
  padding-top: 70px; padding-bottom: 130px; background-color: #D796BC;
  .left_col{
    .name{
      font-size: 18px; line-height: 1.4; font-weight: 700;
    }
    .address{
      font-size: 18px; line-height: 1.4; font-weight: 700; text-decoration: underline; margin-bottom: 30px;
    }
    .copyright{
      font-size: 12px;
    }
  }
  .right_col{
    text-align: right;
    .footer_link{
      margin-bottom: 8px; display: none;
      a{
        font-size: 12px;
      }
    }
    .tel{
      font-size: 18px; line-height: 1.4; font-weight: 700;
    }
    .email{
      font-size: 18px; line-height: 1.4; font-weight: 700; margin-bottom: 25px;
    }
    .social_media{
      a{
        display: inline-block; vertical-align: middle;
        img{
          height: 22px; display: inline-block; vertical-align: middle;
        }
        span{
          display: inline-block; vertical-align: middle; font-size: 22px;
        }
      }
      .instagram{
        margin-left: 20px;
      }
    }
  }
  .mobile{
    display: none;
  }
}

.page_content{
  padding-top: 122px;
}
.banner_section{
  height: 300px; color: $white_color; font-weight: 700; padding-top: 65px; text-align: center; @include backgroundimage;
  opacity: 0; transition: $transition;
  &.show{
    opacity: 1;
  }
  .page_title{
    font-size: 90px;  margin-bottom: 30px; font-family: $playfair_font;
  }
  .desc{
    font-size: 21px; line-height: 1.4; font-family: $playfair_font; @include marginCenter(900); font-weight: 300;
  }
}

.video_content{
  position: relative; background-color: black; width: 100%; overflow: hidden; height: 350px; margin-bottom: 15px; cursor: pointer;
  .video_player{
    width: 100%; display: block;
  }
  .play_btn{
    height: 65px; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; cursor: pointer; opacity: 0;
  }
  &.loaded{
    .play_btn{
      opacity: 1;
    }
  }
  &.on_play{
    .play_btn{
      display: none;
    }
  }
}

a.link_btn{
  height: 50px; line-height: 50px; font-size: 14px; padding-left: 50px; padding-right: 50px; background-color: #5E5E5E;
  color: $white_color; display: inline-block; border-radius: 50px; margin-top: 30px; text-align: center;
}

.services_banner_section{
  color: $white_color; padding-top: 150px; @include backgroundimage; position: relative; background-attachment: fixed;
  min-height: 800px; height: 100vh; opacity: 0; transition: $transition;
  &.show{
    opacity: 1;
  }
  .mobile_title{
    display: none;
  }
  .banner_content{
    position: relative;
  }
  .video_container{
    .video_content{
       max-width: 680px; height: auto;
      .video_player_content{
        width: 100%;
      }
      &.country_video{
        .video_player{
          display: none;
          &.active{
            display: block;
          }
        }
      }
    }

    .team{
      font-size: 14px; line-height: 18px;
    }
    .link{
        font-size: 14px; line-height: 18px; border-bottom: 1px solid $yellow_color; color: $white_color; padding-bottom: 3px; display: inline-block;
    }
    .right_content{
      font-size: 23px; line-height: 1.4;  position: absolute; top: 35px; left: 710px; max-width: 255px; font-family: $playfair_font;
    }
  }
  .text_content{
    position: absolute; left: 480px; top: 380px;
    .page_title{
      font-size: 60px;  margin-bottom: 20px; font-family: $playfair_font;
    }
    .desc{
      font-size: 23px; line-height: 1.3; font-family: $playfair_font;
    }
  }
}

.gallery_section{
  .gallery_trigger{
    max-width: 470px;
  }
  .gallery_trigger_link{
    position: relative; display: block;
    .default_img{
      width: 100%;
    }
    .zoomin{
      position: absolute; height : 45px; left: 0; right: 0; top: 0; bottom: 0; margin: auto; transition: $transition;
    }
    .row{
      margin: -7px;
    }
    .img_block{
      height: 150px; padding: 7px;
    }
    .img_bg{
      @include backgroundimage; width: 100%; height: 100%;
    }
  }
  .gallery_content{
    display: none;
  }
}



#landing_page{
  .video_section{
    position: relative; background-color: black; width: 100%; overflow: hidden; height: 100vh;
    #videoContainer{
      position: absolute; top: 50%; left: 50%; min-width: 100%; min-height: 100%; width: auto; height: auto;
      z-index: 0; transform: translateX(-50%) translateY(-50%);
    }
    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.2;
        z-index: 1;
    }
    .text_section{
      position: relative; z-index: 2; display: flex; align-items: center; height: 100%;
      .page_title{
        font-size: 65px; line-height: 1.4; margin-bottom: 7px; color: $white_color; max-width: 640px; font-family:$playfair_font;
      }
      .desc{
        font-size: 17px; line-height: 1.4; color: $white_color; max-width: 580px; font-weight: 700; margin-bottom: 30px;
        font-family: $primary_font;
        span{
          color: $yellow_color
        }
      }
      .home_btn{
        background-color: $pink_color; height: 50px; line-height: 50px; display: inline-block; padding-left: 25px; transition: 0.3s;
        padding-right: 70px; position: relative; font-size: 14px; font-weight: 500; border-radius: 50px; width: 216px;
        img{
          height: 40px; right: 5px; top: 5px; bottom: 5px; position: absolute;
        }
        &.play_btn{
          margin-top: 12px; background-color: $yellow_color;
          &.not_show{
            display: none;
          }
        }
        &:hover{
          background-color: $yellow_color;
        }
      }
    }
    .sound_section{
      position: absolute; bottom: 25px; z-index: 2; width: 100%; text-align: right;
      .sound_img{
        width: 33px;
      }
      .sound_off{
        display: none;
      }
      #sound_control{
        &.muted{
          .sound_off{
            display: inline;
          }
          .sound_on{
            display: none;
          }
        }
      }
    }
  }
  #footer{
    display: none;
  }
}
#home_page{
  .main_section{
    padding-top: 80px; padding-bottom: 100px; text-align: center;
    .img_content{
      max-width: 650px; margin-left: auto; margin-right: auto; position: relative;
      display: flex; justify-content: center; align-items: center;
      .page_name{
        position: absolute; font-size: 18px; font-family: $roboto_condensed_font; font-weight: 700; width: 140px; text-align: center;
        text-transform: uppercase;
        &.sm{
          top: 86px;


          left: 268px;
        }
        &.de{
          right: -8px;
          top: 227px;
        }
        &.ms{
          right: -10px;
          top: 500px;
        }
        &.pr{
          bottom: 6px; left: 268px;
        }
        &.me{
          left: 16px;
          top: 505px;
        }
        &.sa{
          left: 21px; top: 227px;
        }
        &.quality{
          left: 268px; pointer-events: none;
          top: 282px;
        }
        &.price{
          left: 207px; pointer-events: none;
          top: 389px;
        }
        &.speed{
          left: 329px; pointer-events: none;
          top: 389px;
        }
      }


      .hoplun_cover{
        position: absolute; width: 300px; height: 300px; left: 187px; pointer-events: none; transition: $transition; transition-delay: 2s;
        top: 165px;
        img{
          width: 100%;
        }
      }
      &.active{
        .hoplun_cover{
          opacity: 0;
        }
      }
      svg{
        width: 100%;
      }
      #Group-27{
        transition: $transition; cursor: pointer;
      }
      #Group-30{
        pointer-events: none;
      }
      #Group-27:hover{
        #Group-30{
          opacity: 0;
        }
      }
      #icon_center_o, #Group-13, #Group-12, #Design, #Group-5, #Group-10, #Group-11, #Group-15, #Speed, #Price{
        display: none;
      }
    }
    .icon_content{
      display: none;
      .control_item {
        text-align: center;
        > a {
          display: block;
        }
        .img_container {
          display: block; @include marginCenter(60); margin-bottom: 5px; position: relative; height: 66px;
          img {
            width: 100%;
          }
          .img_hover {
            position: absolute; opacity: 0; transition: $transition; left: 0; top: 0;
          }
        }
        .name {
          font-family: $roboto_condensed_font; text-transform: uppercase; padding-bottom: 30px; display: block; @include marginCenter(150);
          font-size: 13px;line-height: 1.2; font-weight: 700;
        }
      }
    }
    .service_row {
      margin-top: 15px; height: 241px; width: 258px; position: relative; margin-left: auto; margin-right: auto;
      .control_item {
        position: absolute; width: 135px;
        .img_container{
          width: 135px; height: 135px; max-width: 135px; margin: 0;
        }
      }
      }
      .control_item:nth-child(1) {
        top: 0; left: 0; right: 0; margin: auto;
      }
      .control_item:nth-child(2) {
        bottom: 0; left: 0;
      }
      .control_item:nth-child(3) {
        bottom: 0; right: 0
      }
    }
  #icon_1{
    #icon_1_o{
      transition: $transition;
    }
    &:hover{
      #icon_1_o{
        opacity: 0;
      }
    }
  }
  #icon_2{
    #icon_2_o{
      transition: $transition;
    }
    &:hover{
      #icon_2_o{
        opacity: 0;
      }
    }
  }
  #icon_3{
    #icon_3_o{
      transition: $transition;
    }
    &:hover{
      #icon_3_o{
        opacity: 0;
      }
    }
  }
  #icon_4{
    #icon_4_o{
      transition: $transition;
    }
    &:hover{
      #icon_4_o{
        opacity: 0;
      }
    }
  }
  #icon_5{
    #icon_5_o{
      transition: $transition;
    }
    &:hover{
      #icon_5_o{
        opacity: 0;
      }
    }
  }
  #icon_6{
    #icon_6_o{
      transition: $transition;
    }
    &:hover{
      #icon_6_o{
        opacity: 0;
      }
    }
  }
  #icon_center_h{
    > a{
      &:hover{
        opacity: 0.7;
      }
    }
  }
  #pre-production-offic{
    transform: translateY(-10px);
  }
}

#contactus_page{
  .section_header{
    font-size: 65px; font-family: $playfair_font; margin-bottom: 65px;
  }
  .sub_header{
    color: $pink_color; font-size: 30px; font-weight:700; margin-bottom: 30px;
  }
  .item_name{
    font-size: 21px; font-weight: bold; line-height: 1.4;
  }
  .item_tel{
    font-size: 18px; line-height: 1.4; margin-bottom: 25px;
  }
  .item_adderess{
    font-size: 17px; line-height: 1.4;
  }
  .office_section{
    padding-top: 80px;
    .office_section_item{
      margin-bottom: 45px;
    }
    .map_content{
      height: 275px; width: 100%;
    }
  }
  .factory_section{
    .factory_section_item{
      margin-bottom: 70px;
    }
  }
  .contactform_section{
    background-color: $pink_color; padding-top: 60px; padding-bottom: 60px;
    .form_content{
      padding: 0 50px; background-image: url('./../../images/form_background.jpg'); @include marginCenter(950); background-position: bottom left;
      background-repeat: no-repeat; background-color: $white_color;
    }
    .left_col{

    }
    .form_img{
      position: absolute; left: 15px; right: 50px; top: -13px; bottom: -13px;
      background-size: cover; background-position: top center;
    }
    #submit_form{
      padding-top: 40px; padding-bottom: 40px;
      input, textarea{
        width: 100%; background: $white_color; margin-bottom: 20px; padding: 17px; font-size: 14px;
        font-family: $primary_color; outline: none;
        border: 1px solid #DFDFDF; font-weight: 500; letter-spacing: 3px;
      }
      .submit_btn{
        margin-bottom: 0; background-color: $pink_color; color: $white_color; height: 50px; line-height: 50px; padding: 0;
        text-align: center; max-width: 300px; margin-left: auto; display: block;
      }
    }
  }
}

#joinus_page{
  .main_section{
    padding-top: 80px; padding-bottom: 80px;
    > .container{
      max-width: 830px;
    }
    .desc{
      font-size: 17px; line-height: 1.4; text-align: center; margin-bottom: 75px;
    }
    .country_positions{
      text-align: center;
      .country_item{
        margin-bottom: 40px;
      }
      .circle{
        background-color: $pink_color; border-radius: 50%; height: 350px; width: 350px; margin: 0 auto;
        display: flex; justify-content: center; align-items: center; flex-direction: column; position: relative;
      }
      .link{
        position: absolute; width: 100%; height: 100%;
      }
      .country{
        font-family: $playfair_font; font-size: 40px; font-weight: 700; color: $white_color
      }
      .line{
        display: block; height: 1px; border: 0; border-top: 1px solid $yellow_color; margin: 30px 0; padding: 0;
        width: 64px;
      }
      .text{
        font-size: 20px; font-weight: 500; color: $white_color
      }
    }
  }
}

#awards_page {
  .main_section {
    padding-top: 55px; padding-bottom: 120px;
    .slider_text{
      @include marginCenter(750); padding-bottom: 50px; text-align: center; font-size: 16px; line-height: 1.5;
      p{
        margin-bottom: 16px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .slider{
      @include marginCenter(900); position: relative;
      .prev_btn, .next_btn{
        position: absolute; width: 16px; height: 32px; background-image: url('./../../images/arrow_p.png');
        top: 0; bottom: 0; margin: auto; @include backgroundimage;
      }
      .prev_btn{
        left: -32px;
      }
      .next_btn{
        right: -32px; transform: rotate(180deg)
      }
    }
    #content_slider{
       opacity: 0; transition: $transition; position: relative;
      &.slick-initialized{
        opacity: 1;
      }
      .slick-dots {
        bottom: -40px;
        li{
          margin-left: 7px; margin-right: 7px; opacity: 0.5;
          button{
            width: 12px; height: 12px;
            &:before{
              background-color: $pink_color; width: 12px; height: 12px; border: none;
            }
          }
          &.slick-active{
            opacity: 1;
          }
        }
      }
      .slide_item{
        height: 600px; @include backgroundimage;
      }

    }
  }
}

#culture_page{
  .main_section{
    padding-top: 90px; padding-bottom: 70px;
    .top_section{
      padding-bottom: 70px;
      .left_col{
        padding-right: 55px;
        .title{
          font-size: 65px; font-family: $playfair_font; color: $pink_color; margin-bottom: 30px;
        }
        .text{
          font-size: 18px; ; line-height: 1.6;
        }
      }
      .right_col{
        .text{
          font-size: 14px; line-height: 1.1; margin-bottom: 2px;
        }
        .link{
          font-size: 14px; line-height: 1.1; border-bottom: 1px solid $pink_color; font-weight: 700;
        }
      }
    }
    .middle_section{
      text-align: center;
      .title{
        font-size: 65px; font-family: $playfair_font; margin-bottom: 20px;
      }
      .desc{
        font-size: 18px; line-height: 1.4; margin-bottom: 50px;
      }
      .image{
        width: 690px; height: 650px; position: relative; margin-left: auto; margin-right: auto;
        text-align: center;
        .value_item{
          width: 370px; position: absolute; cursor: pointer; transition: 0.3s;
          &:hover{
            transform: scale(1.02);
          }
        }
        .default_img{
          width: 100%;
        }
        .respectful{
          top: 0; left: 0; right: 0; margin: auto;
        }
        .caring{
          bottom: 0; left: 0;
        }
        .fun{
          bottom: 0; right: 0;
        }
        .gallery_content{
          display: none;
        }
      }

    }
  }
  .video_content{
    height: auto;
  }
}

#aboutus_page {
  .main_section {
    .top_section{
      padding-top: 50px; padding-bottom: 40px;
      .desc {
        font-size: 16px; line-height: 1.5; text-align: center; @include marginCenter(700);
      }
    }
    .milestone_section{
      background-color: $pink_color; padding-bottom: 85px;
      .title{
        font-size: 65px; font-family: $playfair_font; padding-top: 80px; padding-bottom: 80px; text-align: center; color: $white_color
      }
      .milestone_content{
        @include marginCenter(860); position: relative;
      }
      .control{
        width: 100px; position: relative; height: 490px;  padding-top: 45px; padding-bottom: 45px; z-index: 1;

        .years_swiper {
          position: relative; height: 100%; overflow: hidden;
          .swiper-wrapper{

          }
          .swiper-slide {
            text-align: center; opacity: 0.45; font-size: 35px; display: flex; justify-content: center; align-items: center;
            height: 42px; font-family: $roboto_condensed_font; cursor: pointer; font-weight: 700;
            &.swiper-slide-active {
              opacity: 1;
            }
          }
        }
        .swiper-button-prev, .swiper-button-next{
          background-image: url("./../../images/arrow.svg"); width: 45px; height: 45px; background-size: cover; margin: auto;
        }
        .swiper-button-prev{
          top: 0; left: 0; right: 0; bottom: auto;
        }
        .swiper-button-next{
          bottom: 0; left: 0; right: 0; top: auto; transform: rotate(180deg);
        }
      }

      .show_content{
        position: absolute; right: 0; bottom: 0; width: calc(100% - 200px);
        .text_content{
          font-size: 18px; line-height: 1.4; color: $white_color; margin-bottom: 45px;
        }
        .image_swiper{
          margin-left: -32px;
        }
        .swiper-slide {
          background-position: center; background-size: cover; height: 420px;
        }
        .inner_swiper{
          height: 100%; padding-bottom: 20px; padding-left: 32px; padding-right: 32px;
          .swiper-wrapper{

          }
          .swiper-pagination{
            bottom: -20px; left: 0; right: 0;
            .swiper-pagination-bullet{
               margin-left: 5px; margin-right: 5px; background-color: rgba(255,255,255,0.4)
            }
            .swiper-pagination-bullet-active{
              background-color: rgba(255,255,255,1);
            }
          }
        }
        .swiper-slide{
          .swiper-pagination-bullet{
            opacity: 0;
          }
          &.swiper-slide-active{
            .swiper-pagination-bullet{
              opacity: 1;
            }
          }
        }
        .inner_slide{
          @include backgroundimage; width: 100%; height: 100%;
        }
      }


    }
    .mission_section{
      padding-top: 100px; padding-bottom: 30px; text-align: center; @include backgroundimage;
      .title{
        font-size: 65px; font-family: $playfair_font;; text-align: center; margin-bottom: 50px;
      }
      .name{
        font-size: 22px; margin-bottom: 30px; font-weight: 700;
      }
      .content{
        font-size: 18px; line-height: 1.3; @include marginCenter(590);
      }
      .section{
        margin-bottom: 70px;
      }
    }
  }
  .swiper-button-next, .swiper-button-prev{
    background-image: url('./../../images/arrow_w.png'); width: 16px; height: 32px; background-size: cover; background-repeat: no-repeat; margin-top: -16px;
    display: none;
  }
  .swiper-button-prev{
    left: -32px;
  }
  .swiper-button-next{
    transform: rotate(180deg); right: -32px;
  }
  .swiper-slide-active{
    .swiper-button-next, .swiper-button-prev{
      display: block;
    }
  }
}

#sales_merchandising_page{
  .sales_merchandising_section{
    padding: 30px 25px; background-color: $white_color; position: relative; margin-bottom: 80px;
    .flex{
      display: flex;
    }
    .section_left{
      flex: 1; padding-right: 30px;
    }
    .title{
      font-size: 40px; font-family: $playfair_font; margin-bottom: 30px;
    }
    .section_top_img{
      margin-bottom: 45px; max-width: 100%;
    }
    .content{
      font-size: 17px; line-height: 1.4; max-width: 570px;
      p{
        margin-bottom: 15px;
      }
    }
    .section_right{
      width: 425px;
      .section_right_img{
        width: 100%; margin-bottom: 15px;
      }
    }
  }
  .main_section{
    background-color: $pink_color; padding-top: 80px; padding-bottom: 90px;
    .section_title{
      font-size: 65px; color: $white_color; font-family: $playfair_font; margin-bottom: 70px;
    }
    .block_container{
      height: 850px; position: relative;
    }
    .section_block{
      padding: 7px;
    }
    .block_content{
      background-color: $white_color; padding: 30px 25px; height: 100%; position: relative;
    }
    .block_title{
      font-size: 21px; line-height: 1.1; margin-bottom: 20px;
      > a{
        transition: 0.3s;
        &:hover{
          color: $pink_color
        }
      }

    }
    .block_text{
      font-size: 17px; line-height: 1.4;
    }
    .block_img{
      position: absolute; @include backgroundimage
    }
    .top_left{
      width: 66.66%; height: 33.33%;
      .block_img{
        width: 300px; top: 30px; bottom: 30px; right: 25px;
      }
      .block_content{
        padding-right: 360px;
      }
    }
    .top_right{
      width: 33.33%; position: absolute; right: 0; top: 0; height: 66.66%;
      .block_img{
        height: 250px; left: 25px; bottom: 30px; right: 25px;
      }
      .block_content{
        padding-bottom: 270px;
      }
    }
    .bottom_left{
      height: 66.66%; width: 33.33%; position: absolute; bottom: 0; left: 0;
      .block_img{
        height: 180px; left: 25px; bottom: 30px; right: 25px;
      }
      .block_content{
        padding-bottom: 200px;
      }
    }
    .bottom_right{
      height: 33.33%; width: 66.66%; position: absolute; bottom: 0; right: 0;
      .block_img{
        width: 300px; top: 30px; bottom: 30px; right: 25px;
      }
      .block_content{
        padding-right: 350px;
      }
    }
    .middle{
      height: 33.33%; width: 33.33%; left: 33.33%; top: 33.33%; position: absolute;
    }
  }
}

#design_service_page{
  .main_section{
    .middle_section{
      background-color: $pink_color;
      .section_item{
        padding-top: 80px;  padding-bottom: 80px; color: $white_color;
      }
      .title{
        font-size: 65px; font-family: $playfair_font;; margin-bottom: 30px;
      }
      .content{
        font-size: 16px; line-height: 1.4; max-width: 600px;
        .country{
          font-weight: 700;
        }
        .country span{
          display: inline-block; width: 130px; font-weight: 400;
        }
      }
      .img_content{
        > img{
          border-radius: 15px; width: 100%;
        }
      }
      .mobile_img{
        display: none;
      }
    }
    .working_options_section{
      background-color: $pink_color; padding-top: 40px; padding-bottom: 120px;
      .title{
        font-size: 65px; font-family: $playfair_font;; margin-bottom: 30px; text-align: center; color: $white_color; margin-bottom: 40px;
      }
      .control{
        text-align: center; margin-bottom: 50px;
        .control_item{
          width: 50px; height: 50px; display: inline-block; border: 17px solid #F8DDED; border-radius: 100%; box-sizing: content-box;
          margin-left: 30px; margin-right: 30px; opacity: 0.5; transition: $transition;
          > a{
            display: flex; justify-content: center; align-items: center; height: 100%; font-family: $playfair_font;
          }
          &.active{
            opacity: 1;
          }
        }
      }
      #work_options_slider{
        @include marginCenter(1100); background-color: $white_color; opacity: 0; transition: $transition;
        &.slick-initialized{
          opacity: 1;
        }
      }
      .control_content_item{
        padding: 50px;; position: relative;
        .text_content{
          width: 50%; max-width: 525px; padding-right: 25px;
        }
        .name{
          font-size: 21px; font-weight: 700; margin-bottom: 30px;
        }
        .desc{
          font-size: 17px; line-height: 1.4; margin-bottom: 30px;
          span, bold{
            font-weight: 700;
          }
        }
        .bg_img{
          position: absolute; width: calc(50% - 50px); max-width: 420px; right: 50px; top: 50px; height: 340px; @include backgroundimage
        }
        .chart{
          position: relative; display: flex; padding-left: 230px; height: 190px; align-items: center;
          .donut {
            font-weight: 700; font-size: 18px; width: 190px; height: 190px; position: absolute; left: 0;
          }
          path.color0 { fill:  $pink_color; }
          path.color1 { fill:  $yellow_color;}
          .chart_text{
            .percentage{
              font-size: 50px; color: $pink_color; font-weight: 700;
              &.yellow{
                color: $yellow_color; margin-top: 10px;
              }
            }
            .text{
              font-size: 16px; font-weight: 700; line-height: 1.3;
            }
          }
        }
      }
    }
    .product_development_section{
      padding-top: 40px; padding-bottom: 120px;
      .title{
        font-size: 65px; font-family: $playfair_font;; margin-bottom: 30px; text-align: center;  margin-bottom: 40px;
      }
      .control{
        text-align: center; margin-bottom: 50px;
        .control_item{
          width: 50px; height: 50px; display: inline-block; border: 17px solid #F8DDED; border-radius: 100%; box-sizing: content-box;
          margin-left: 30px; margin-right: 30px; transition: $transition;
          > a{
            display: flex; justify-content: center; align-items: center; height: 100%; font-family: $playfair_font;
          }
          &.active{
            border-color: $yellow_color
          }
        }
      }
      #product_development_slider {
        @include marginCenter(1100); background-color: $white_color; opacity: 0; transition: $transition;
        &.slick-initialized{
          opacity: 1;
        }
      }
      .control_content_item{
        position: relative;
        .text_content{
          width: 55%; max-width: 600px; min-height: 280px; padding-right: 25px; padding-top: 15px;
        }
        .name{
          font-size: 21px; font-weight: 700; margin-bottom: 10px;
        }
        .desc{
          font-size: 17px; line-height: 1.4;
          span, bold{
            font-weight: 700;
          }
        }
        .video_content, .gallery_section{
          position: absolute; width: 45%; max-width: 480px; right: 0px; top: 0px; height: 280px;
        }
      }
    }
    .collection_section{
      padding-top: 80px; padding-bottom: 80px; background-color: $pink_color; color: $white_color;
      .title{
        font-size: 65px; font-family: $playfair_font;; margin-bottom: 30px; margin-bottom: 40px;
      }
      .mobile_img{
        display: none;
      }
      .collection_container{
        @include marginCenter(1100);
      }
      .collection_content{
        position: relative;
      }
      .text_content {
        width: 55%; max-width: 600px; min-height: 500px; padding-right: 25px; padding-top: 15px;
        .name{
          font-size: 21px; font-weight: 700; margin-bottom: 10px;
        }
        .desc{
          font-size: 17px; line-height: 1.4;
          span{
            font-weight: 700;
          }
        }
      }
      .bg_img{
        position: absolute; width: 45%; max-width: 480px; right: 0px; top: 0px; height: 510px; @include backgroundimage;
      }
    }
  }
}

#material_sourcing_page{
  .material_sourcing_section{
    padding: 30px 25px; background-color: $white_color; position: relative; margin-bottom: 80px;
    .flex{
      display: flex;
    }
    .section_left{
      flex: 1; padding-right: 30px;
    }
    .ms_title{
      font-size: 40px; font-family: $playfair_font; margin-bottom: 30px;
    }
    .section_top_img{
      margin-bottom: 45px; max-width: 100%;
    }
    .content{
      font-size: 17px; line-height: 1.4; max-width: 570px;
      p{
        margin-bottom: 15px;
      }
      img{
        margin-bottom: 25px; margin-top: 10px; max-width: 100%;
      }
    }
    .section_right{
      width: 425px;
      .section_right_img{
        width: 100%; margin-bottom: 15px;
      }
    }
  }
  .middle_section{
    background-color: $pink_color; padding-bottom: 100px; padding-top: 80px;
    .title{
      font-size: 65px; font-family: $playfair_font;;text-align: center;  margin-bottom: 40px; color: $white_color
    }
    .cost_content{
      text-align: center;
    }
    .row{
      margin-left: -20px; margin-right: -20px;
    }
    .cost_item{
      padding-left: 20px; padding-right: 20px;
    }
    .col_container{
      padding: 20px 15px; background-color: $white_color; height: 100%;
      .top_img{
        height: 115px;
        img{
          height: 100px;
        }
      }
      .name{
        font-size: 21px; font-weight: 700; margin-bottom: 15px; @include marginCenter(245);
      }
      .desc{
        font-size: 17px; line-height: 1.4;
        span, bold{
          font-weight: 700;
        }
      }
    }
  }
  .vendors_section{
    padding-top: 65px; text-align: center;
    .title{
      font-size: 65px; font-family: $playfair_font; text-align: center;  margin-bottom: 40px;
    }
    .desc{
      font-size: 17px; line-height: 1.4; color: $pink_color; margin-bottom: 70px;
    }
    .vendors{
      text-align: center;
      img{
        max-width: 100%;
      }
      .row{
        justify-content: center; align-items: center;
      }
      .vendor_item{
        flex: 0 0 20%; max-width: 20%; margin-bottom: 85px;
      }
    }
  }
}

#preproduct_office_page{
  .main_section{
    background-color: $pink_color; padding-top: 70px; padding-bottom: 35px;
  }

  .section_item{
    @include marginCenter(1100); background-color: $white_color; padding: 50px;; position: relative; margin-bottom: 35px;
    .text_content{
      width: 50%; max-width: 525px; min-height: 300px; padding-right: 25px;
    }
    .name{
      font-size: 21px; font-weight: 700; margin-bottom: 30px;
    }
    .desc{
      font-size: 17px; line-height: 1.4;
      span, bold{
        font-weight: 700;
      }
    }
    .bg_img{
      position: absolute; width: calc(50% - 50px); max-width: 420px; right: 50px; top: 50px; height: 300px; @include backgroundimage;
    }

  }
}

#manufacturing_excellence_page{
  .main_section{
    .content_control{
      background-color: #3F3F3F; padding-left: 15px; padding-right: 15px;
    }
    .content_control_item{
      font-size: 25px; font-family: $playfair_font; text-align: center; height: 100px; line-height: 100px;
      > a{ display: block; color: $white_color;}
      &.active{
        background-color: $pink_color;
      }
    }
    .content_sections{
      background-color: $pink_color;
    }
    .content_section_item{
      padding-top: 70px; padding-bottom: 110px;
      .top_content{
        font-size: 18px; line-height: 1.3; color: $white_color; margin-bottom: 30px;
      }
      .slide_content.mobile{display: none}
      .slide_title{
        font-size: 18px; color: $white_color; line-height: 1.3; margin-bottom: 3px;
      }
      .slide_text{
        font-size: 18px; line-height: 1.3;
      }
      .content_slider{
        opacity: 0; transition: $transition;
        &.slick-initialized{
          opacity: 1;
        }
        .slide_item{
          height: 310px; width: 100%; @include backgroundimage;
        }
        .slick-dots {
          bottom: -40px;
          li{
            margin-left: 7px; margin-right: 7px; opacity: 0.5;
            button{
              width: 12px; height: 12px;
              &:before{
                background-color: $white_color; width: 12px; height: 12px; border: none;
              }
            }
            &.slick-active{
              opacity: 1;
            }
          }
        }
      }
    }
    .country_factory_section{
      padding-bottom: 100px;
      .section_item{
        padding-top: 70px;
        .section_title{
          font-size: 65px; font-family: $playfair_font; margin-bottom: 30px;
        }
        .duty{
          font-size: 18px; line-height: 1.3; margin-bottom: 30px;
          .head{
            font-weight: 700;
          }
        }
        .factory_section{
          > .row{
            margin-left: -25px; margin-right: -25px;
          }
          .factory_section_item{
            padding-left: 25px; padding-right: 25px;
          }
          .item_content{
            img{
              width: 100%; margin-bottom: 20px;
            }
            .text{
              font-size: 22px; line-height: 1.2; margin-bottom: 20px; font-weight: 700;
            }
            .desc{
              font-size: 16px; line-height: 1.5;
            }
          }
        }
      }
    }
  }
}
.services_page{
  .top_section{
    padding-top: 70px; padding-bottom: 90px;
    .left_col{
      padding-top: 70px; font-size: 18px; line-height: 1.4; padding-right: 100px;
      .title{
        font-weight: 700; margin-bottom: 20px;
      }
    }
    .right_col{
      img{
        width: 100%; margin-bottom: 25px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      .gallery_section .gallery_trigger_link .zoomin{
        margin-bottom: auto;
      }
    }
  }
  .bottom_section{
    padding-top: 45px;
    .control_item{
      text-align: center;
      > a{
        display: block; opacity: 0.45; transition: $transition;
        &.active{
          opacity: 1;
        }
        &:hover{
          opacity: 1;
          .img_hover{
            opacity: 1;
          }
        }
      }
      .img_container{
        display: block; @include marginCenter(85); margin-bottom: 10px; position: relative;
        img{
          width: 100%;
        }
        .img_hover{
          position: absolute; opacity: 0; transition: $transition; left: 0; top: 0;
        }
      }
      .name{
        font-family: $roboto_condensed_font; font-weight: 700; text-transform: uppercase; padding-bottom: 20px; display: block; @include marginCenter(150);
      }
    }
    .content_section{
      background-color: $pink_color;  color: $white_color; padding-bottom: 60px;
      .content_item{
        padding-top: 60px; padding-bottom: 40px;
      }
      .title{
        font-size: 65px; font-family: $playfair_font; margin-bottom: 30px;
      }
      .text{
        font-size: 18px; line-height: 1.4;
      }
      .right_col{
        img{
          width: 100%;
        }
      }
      .content_slide{
        opacity: 0; transition: $transition;
        &.slick-initialized{
          opacity: 1;
        }
      }
    }
  }
}

#speed_page{

  .right_col{
    text-align: center;
    > svg{
      max-width: 100%;
    }
    .chart_text{
      font-size: 35px;
      font-weight: 500;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 70%; margin: auto;
      > div{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    #text_1, #dots_line
    {
      transition: $transition;
    }
    #right_line, #bottom_line, #left_line, #text_2, #text_3, #text_4{
      opacity: 0; transition: $transition;
    }
    &.active {
      #dots_line, #text_1 {
        animation-name: fadeinout_anim; animation-duration: 8s; animation-iteration-count: infinite;

      }
      #right_line{
        animation-name: fadeinout_anim; animation-duration: 8s; animation-delay: 2s; animation-iteration-count: infinite;
      }
      #text_2 {
        animation-name: fadeinout_anim; animation-duration: 8s; animation-delay: 2s; animation-iteration-count: infinite;
      }
      #bottom_line {
        animation-name: fadeinout_anim; animation-duration: 8s; animation-delay: 4s; animation-iteration-count: infinite;
      }
      #text_3 {
        animation-name: fadeinout_anim; animation-duration: 8s; animation-delay: 4s; animation-iteration-count: infinite;
      }
      #left_line, #text_4 {
        animation-name: fadeinout_anim; animation-duration: 8s; animation-delay: 6s; animation-iteration-count: infinite;
      }
    }
  }
}

@keyframes text_fadeinout{
  0%, 100% {
    opacity: 0;
  }
  10%, 90% {
    opacity: 1;
  }
}
@keyframes fadeinout_anim {
  0%{
    opacity: 0;
  }
  8%{
    opacity: 1;
  }
  20%{
    opacity: 1;
  }
  28%{
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}



#sustainability_page{
  .top_section{
    background-color: #8CC53F; color: $white_color; text-align: center;
    .title{
      font-size: 65px; font-family: $playfair_font; margin-bottom: 30px; @include marginCenter(1000);
    }
    .desc{
      font-size: 21px; line-height: 1.4; font-family: $roboto_condensed_font; @include marginCenter(850); font-weight: 700;
    }
  }
  .middle_section{
    .row{
      margin-left: 0; margin-right: 0;
    }
    .section_item{
      padding-left: 0; padding-right: 0;
    }
    figure{
      height: 640px; width: 100%; display: block;
      .img_bg{
        @include backgroundimage; height: 100%; width: 100%; display: flex; justify-content: center; align-items: center;
      }
      .item_name{
        font-size: 45px; color: $white_color; line-height: 1.2; max-width: 60%; text-align: center;
      }
      figcaption{
        display: flex; justify-content: center; align-items: center; flex-direction: column; text-align: center;
      }
      h3{
        font-size: 30px; margin-bottom: 35px;
      }
      p{
        font-size: 17px; line-height: 1.4; max-width: 70%;
      }
    }
  }
  .report_section{
    padding-top: 60px; padding-bottom: 60px;
    .section_title{
      color: #8DC53F; font-family: $playfair_font; font-size: 65px; margin-bottom: 35px;
    }
    .section_images{
      text-align: center; padding-bottom: 5px;
    }
    .section_image{

        height: 50px; margin-bottom: 45px; display: block; margin-left: auto; margin-right: auto;

    }
    .section_text{
      font-size: 17px; margin-bottom: 70px;
      > a{
        color: #8DC53F
      }
    }
    .filter{
      padding-bottom: 40px; border-bottom: 1px solid #E5E5E5;
      .text{
        font-size: 18px; line-height: 1.3; font-weight: 700; display: inline-block; vertical-align: middle;
        margin-right: 20px;
      }
      .selectric-wrapper{
        display: inline-block; vertical-align: middle; width: 195px;
        .label{
          font-size: 18px; margin-left: 15px;
        }
        .selectric{
          border-color: #ccc; background-color: $white_color;
          .button{
            color: #8DC53F; background-color: $white_color;
            &:after{
              border-top-color: #8DC53F; border-width: 6px;
            }
          }
        }
        .selectric-items{
          background-color: $white_color;
          li{
            font-size: 16px; padding-left: 15px;
          }
        }
      }
    }
    .report_item{
      padding-top: 38px; padding-bottom: 27px; border-bottom: 1px solid #E5E5E5; position: relative; display: none;
      padding-right: 50px;
      &.active{
        display: block;
      }
      .report_name{
        font-size: 20px; font-weight: 500; margin-bottom: 13px; line-height: 1.2;
      }
      .report_date{
        color: #8DC53F; font-size: 16px;
      }
      .download{
        position: absolute; height: 30px; right: 0; top: 0; bottom: 0; margin: auto;
        img{
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 576px){

}

@media only screen and (min-width: 768px){
  #header_menu{
    &.in_scroll{
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      #site_logo{
        height: 52px;
      }
      #header_main_menu{
        padding-top: 20px; padding-bottom: 20px;
      }
    }
  }
  .services_banner_section {
    .video_container{
      .country{
        width: 170px; font-family: $playfair_font; font-size: 18px; text-align: center; margin-top:  35px;
        .country_item{
          height: 46px; line-height: 46px; border: 1px solid $white_color; border-top: none; display: block; color: $white_color;
          &.active{
            background-color: rgba(63,63,63,0.84);
          }
          &:first-child{
            border-top: 1px solid $white_color;
          }
        }
      }
    }
  }


}
@media only screen and (min-width: 992px){
  .content_container{
    padding-left: 85px; padding-right: 85px;
  }
  #contactus_page {
    .office_section, .factory_section {
      > .container {

      }
    }
  }
  #responsive_btn{
    display: none;
  }
}

@media only screen and (min-width : 992px) and (max-width : 1199px) {

}
@media only screen and (min-width: 1200px){

}
@media only screen and (min-width: 1400px){

}

@media only screen and (max-width : 1399px) {


}
@media only screen and (max-width : 1199px) {
  .container{
    padding-left: 20px; padding-right: 20px;
  }
  .banner_section {
    .page_title{
      font-size: 72px;
    }
  }
  .services_banner_section {
    .video_container{
      .video_content{
        width: 65%
      }
      .right_content{
        left: auto; right: 0; width: 30%;
      }
    }
    .text_content {
      left: auto; right: 0; max-width: 500px;
      .page_title{
        font-size: 60px;
      }
      .desc{
        font-size: 18px; line-height: 1.3;
      }
    }
  }
  #sales_merchandising_page {
    .main_section {
      .block_container{
        height: 1100px;
      }
      .top_left , .bottom_right{
        .block_img{
          width: 250px;
        }
        .block_content{
          padding-right: 300px;
        }
      }
    }
  }
  #sustainability_page {
    .middle_section {
      figure{
        height: 400px;
        .item_name{
          font-size: 36px;
        }
        p{
          max-width: 90%;
        }
      }
    }
  }
}


@media only screen and (max-width : 991px) {
  #scroll_top{right: 20px; }
  .services_banner_section {
    .video_container {
      .right_content{
        font-size: 18px;
      }
    }
  }
  #header_menu{
    height: 100px;
    > .container{
      height: 100%;
    }
    #header_main_menu{
      display: none;
    }
    #header_right_menu{
      display: none;
    }
  }
  .page_content{
    padding-top: 100px;
  }
  #awards_page{
    .main_section{
      .container{
        padding-left: 40px; padding-right: 40px;
      }
    }
  }
  #sales_merchandising_page {
    .sales_merchandising_section {
      .flex{
        display: block;
      }
      .section_left {
        position: relative; padding-right: 0; padding-bottom: 25px;
      }
      .section_right{
        width: 100%;
        .section_right_img{
          width: calc(50% - 9px); margin-right: 13px;
          &:nth-child(even){
            margin-right: 0;
          }
        }
      }
    }
    .main_section {
      .section_title{
        font-size: 48px;
      }
      .block_container{
        display: flex; flex-wrap: wrap; margin-left: -7px; margin-right: -7px; height: auto;
      }
      .section_block{
        flex: 0 0 50%; position: relative; max-width: 50%;
      }
      .block_img{
        bottom: 30px;
      }
      .section_block{
        width: auto; height: auto;
        .block_content{
          padding: 30px 25px 300px;
        }
        .block_img{
          width: auto; left: 25px; right: 25px; height: 220px; bottom: 30px; top: auto;
        }
      }
      .middle{
        left: 0; right: 0; margin: auto;
        .block_content{
          padding-bottom: 20px;
        }
      }
    }
  }
  #design_service_page {
    .main_section {
      .working_options_section {
        .control_content_item {
          .chart{
            height: auto; padding-left: 0; text-align: center; flex-wrap: wrap; width: 250px; text-align: center;
            .donut{
              position: relative; margin-bottom: 10px; margin-left: auto; margin-right: auto;
            }
            .chart_text{
              margin-left: auto; margin-right: auto;
              .percentage{
                font-size: 40px;

              }
              .text{
                font-size: 16px; line-height: 1.5;
              }
            }
          }
        }
      }
    }
  }
  #joinus_page {
    .main_section {
      .country_positions {
        .circle{
          width: 230px; height: 230px;
        }
        .country{
          font-size: 42px;
        }
        .text{
          font-size: 18px;
        }
      }
    }
  }
  #culture_page {
    .main_section {
      .top_section {
        .left_col {
          .title{
            font-size: 48px;
          }
        }
      }
      .middle_section {
        .title{
          font-size: 48px;
        }
      }
    }
  }
  #material_sourcing_page {
    .material_sourcing_section {
      .flex{
        display: block;
      }
      .section_left {
        position: relative; padding-right: 0; padding-bottom: 25px;
      }
      .section_right{
        width: 100%;
        .section_right_img{
          width: calc(50% - 9px); margin-right: 13px;
          &:nth-child(even){
            margin-right: 0;
          }
        }
      }
    }
    .middle_section {
      .row{
        margin-left: -10px; margin-right: -10px;
      }
      .cost_item{
        padding-left: 10px; padding-right: 10px;
      }
    }
    .vendors_section {
      .vendors {
        .vendor_item{
          flex: 0 0 25%; max-width: 25%;
        }
      }
    }
  }
  #manufacturing_excellence_page {
    .main_section {
      .country_factory_section {
        .section_item {
          .factory_section {
            > .row{
              margin-left: -10px; margin-right: -10px;
            }
            .factory_section_item{
              padding-left: 10px; padding-right: 10px;
            }
          }
        }
      }
    }
  }
  .services_page {
    .top_section {
      .left_col{
        padding-top: 0;
      }
    }
    .bottom_section {
      .control_item {
        .img_container {
          max-width: 60px;
        }
        .name{
          font-size: 14px;
        }
      }
      .content_section {
        .title{
          font-size: 48px;
        }
      }
    }
  }
  #sustainability_page {
    .top_section {
      .title{
        font-size: 48px;
      }
    }
    .middle_section {
      figure{
        height: 360px;
        .item_name{
          font-size: 28px;
        }
        h3{
          font-size: 26px; margin-bottom: 20px;
        }
        p{
          max-width: 90%;
        }
      }
    }
  }
  #speed_page {
    .top_section {
      .left_col{
        padding-top: 70px;
      }
    }
  }
}
@media only screen and (min-width : 768px) and (max-width : 991px) {

}
@media only screen and (max-width : 767px) {
  body{
    font-size: 14px;
  }
  .page_content{
    padding-top: 0;
  }
  .gallery_section {
    .gallery_trigger_link {
      .default_img{
        width: auto;
      }
    }
  }
  #header_menu {
    height: 75px; background-color: transparent;
    #site_logo{
      height: 48px;
    }
    &:not(.in_scroll){
      .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before{
        background-color: $white_color;
      }
    }
    #responsive_menu {
      overflow: scroll;
      .bottom_menu{
        position: relative; bottom: 0; padding-left: 40px; left: 0; padding-top: 50px; padding-bottom: 50px;
      }
    }
  }
  .mobile_transparent_header{
    #header_menu {
      .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before{
        background-color: $primary_color;
      }
    }
  }

  .video_content {
    .play_btn{
      height: 45px;
    }
  }


  #footer {
    .left_col {
      .name{
        font-size: 16px;
      }
      .address{
        font-size: 16px;
      }
      .copyright{
        display: none;
      }
    }
    .right_col {
      .footer_link{
        display: none;
      }
      .tel{
        font-size: 16px;
      }
      .email{
        font-size: 16px;
      }
      .social_media {
        a {
          img{
            height: 16px;
          }
          span{
            font-size: 16px;
          }
        }
      }
    }
    .mobile{
      display: block;
      .footer_link{
        font-size: 12px; margin-bottom: 30px; display: none;
      }
      .copyright{
        font-size: 12px;
      }
    }
  }
  .banner_section {
    padding-top: 100px; text-align: left; height: 350px;
    .page_title{
      font-size: 32px;
    }
    .desc{
      font-size: 18px; line-height: 1.3;
    }
  }
  .services_banner_section {
    padding-top: 110px; height: auto; min-height: 670px; padding-bottom: 25px;
    .mobile_title{
      display: block; font-size: 32px; font-family: $playfair_font; margin-bottom: 25px;
    }
    .video_container {
      .team{
        font-size: 12px; margin-bottom: 5px;
      }
      .video_content{
        width: 100%; margin-bottom: 10px; height: auto;
      }
       .right_content{
         position: relative; width: 100%; max-width: 100%; top: 0; line-height: 1.3; margin-bottom: 24px;
       }
      .link{
        margin-bottom: 10px;
        > a{font-size:12px;}
      }
      .country {
        position: absolute; top: 0; margin-top: 0; width: 100%; text-align: center;
        .country_item{
          color: $white_color; font-family: $playfair_font; font-weight: 700; display: inline-block; margin-left: 22px; margin-right: 22px;
          padding-left: 8px; padding-right: 8px; padding-bottom: 4px;
          &.active{
            border-bottom: 1px solid $white_color;
          }
        }
      }
    }

    .text_content{
      position: relative; top: 0; max-width: 100%;
      .page_title{
        display: none;
      }
      .desc{
        font-size: 18px;
      }
    }
  }
  #landing_page {
    .video_section{
      .text_section {
        .page_title{
          text-align: center; font-size: 32px; max-width: 100%; margin-bottom: 15px;
        }
        .desc{
          max-width: 100%; font-size: 15px; line-height: 1.5; text-align: center; margin-bottom: 25px;
        }
      }
      .btn_container{
        text-align: center;
        .home_btn{
          width: 240px; text-align: left;
        }
      }
    }
  }

  #home_page {
    .main_section {
      .img_content{
        display: none;
      }
      .icon_content{
        display: block; padding-top: 100px; padding-bottom: 20px;
      }
    }
  }


  #contactus_page {

    .section_header{
      font-size: 25px; margin-bottom: 20px;
    }
    .sub_header{
      font-size: 22px;
    }
    .item_name{
      font-size: 18px;
    }
    .item_tel{
      font-size: 16px; margin-bottom: 16px;
    }
    .item_adderess{
      font-size: 16px; line-height: 1.4; padding-bottom: 20px;
    }
    .office_section{
      padding-top: 35px;
      .office_section_item{
        margin-bottom: 55px;
      }
      .sub_header{
        margin-bottom: 0;
      }
    }
    .factory_section{
      padding-bottom: 20px;
      .item_adderess{
        padding-bottom: 30px;
      }
      .factory_section_item{
        margin-bottom: 0;
      }
    }
    .contactform_section{
      padding-top: 30px; padding-bottom: 30px;
      .form_content{
        padding-left: 20px; padding-right: 20px;
      }
      .left_col{
        display: none;
      }
      #submit_form{
        padding-top: 35px; padding-bottom: 35px;
        .submit_btn{
          margin-top: 30px; max-width: 100%;
        }
      }
    }
  }
  #joinus_page {
    .main_section {
      padding-top: 35px; padding-bottom: 15px;
      .desc{
        font-size: 16px; line-height: 1.5; margin-bottom: 40px;
      }
      .country_positions {
        .circle{
          width: 155px; height: 155px;
        }
        .country{
          font-size: 22px; line-height: 1;
        }
        .text{
          font-size: 14px;
        }
        .line{
          margin-top: 10px; margin-bottom: 10px;
        }
        .country_item{
          margin-bottom: 25px;
        }
      }
    }
  }
  #awards_page {
    .main_section{
      padding-top: 35px; padding-bottom: 100px;
      #content_slider {
        max-width: 100%;
        .slide_item{
          height: 300px;
        }
        .slick-dots{
          bottom: -30px;
        }
      }
    }
  }
  #sales_merchandising_page {
    .sales_merchandising_section{
      padding: 20px 15px 0;
      .section_right .section_right_img{
        width: 100%; margin-right: 0;
      }
    }
    .main_section{
      padding-top: 50px; padding-bottom: 50px;
      .section_title{
        font-size: 25px; margin-bottom: 20px;
      }
      .section_block {
        .block_content{
          padding: 20px 15px 250px;
          .block_img{
            bottom: 10px; left: 15px; right: 15px;
          }
        }
      }
      .middle {
        .block_content{
          padding-bottom: 20px;
        }
      }
      .block_title{
        font-weight: 700; font-size: 20px; margin-bottom: 10px;
      }
      .block_text{
        font-size: 16px; line-height: 1.5;
      }
    }
  }
  #design_service_page {
    .main_section {
      .middle_section {
        padding-bottom: 40px;
        .section_item{
          padding-top: 45px; padding-bottom: 0;
        }
        .title{
          font-size: 25px; margin-bottom: 25px;
        }
        .content{
          max-width: 100%;
          p{
            line-height: 1.5;
          }
        }
        .mobile_img{
          display: block; text-align: center; margin-bottom: 30px;
          img{
            max-width: 100%;
          }
        }
        .img_content{
          display: none;
        }
        .btn_container{
          text-align: center;
        }
        a.link_btn{
          width: 230px;
        }
      }
      .working_options_section{
        padding-top: 40px; padding-bottom: 50px;
        .title{
          font-size: 25px; margin-bottom: 25px;
        }
        .control {
          margin-bottom: 20px;
          .control_item > a{
            line-height: 1.1;
          }
        }
        .control_content_item{
          .name{
            font-size: 20px;
          }
          .desc{
            font-size: 16px; line-height: 1.5;
          }
          .text_content{
            width: 100%; max-width: 100%; padding-right: 0; padding-bottom: 20px;
          }
          .bg_img{
            position: static; max-width: 400px; width: 100%; margin-left: auto; margin-right: auto;
          }
          .chart{
            margin-left: auto; margin-right: auto;
          }
        }
      }
      .product_development_section {
        padding-bottom: 50px;
        .title{
          font-size: 25px; margin-bottom: 25px;
        }
        .control_content_item {
          .text_content{
            width: 100%; max-width: 100%; padding-right: 50px; padding-left: 50px; min-height: 0; margin-bottom: 20px;
          }
          .video_content, .gallery_section{
            position: relative; height: 340px; max-width: 400px; width: 100%; width: 100%; margin-left: auto; margin-right: auto;
          }
          .gallery_section{
            margin-top: 40px;
          }
          .name{
            font-size: 20px;
          }
          .desc{
            font-size: 16px; line-height: 1.5;
          }
        }
      }
      .collection_section{
        padding-top: 40px; padding-bottom: 45px;
        .title{
          font-size: 25px; margin-bottom: 25px;
        }
        .bg_img{
          display: none;
        }
        .mobile_img{
          display: block; position: static; width: 100%; max-width: 400px; height: 400px;
          margin-left: auto; margin-right: auto; margin-bottom: 20px;
        }
        .text_content{
          width: 100%; max-width: 100%; padding-top: 0; padding-right: 0;
          .name{
            font-size: 20px;
          }
          .desc{
            font-size: 16px; line-height: 1.5;
          }
        }
        .btn_container{
          text-align: center;
        }
        a.link_btn{
          width: 230px;
        }
      }
    }
  }
  #material_sourcing_page {
    .material_sourcing_section{
      padding: 20px 15px 0;
      .section_right .section_right_img{
        width: 100%; margin-right: 0;
      }
    }
    .middle_section{
      padding-top: 45px; padding-bottom: 35px;
      .title{
        font-size: 25px; margin-bottom: 25px; text-align: left;
      }
      .col_container{
        padding: 30px 15px;
        .name{
          font-size: 20px; line-height: 1.3;
        }
        .desc{
          font-size: 16px; line-height: 1.5; @include marginCenter(500);
        }
      }
      .cost_item{
        margin-bottom: 15px;
      }
    }
    .vendors_section {
      padding-top: 45px; padding-bottom: 50px;
      .title{
        font-size: 25px; text-align: left; margin-bottom: 50px;
      }
      .desc{
        font-size: 16px; line-height: 1.5; text-align: left; margin-bottom: 50px;
      }
      .vendors{
        .vendor_item{
          flex: 0 0 33.33%; max-width: 33.33%; margin-bottom: 30px;
        }
      }
    }
  }
  #preproduct_office_page {
    .main_section{
      padding-top: 45px; padding-bottom: 50px;
    }
    .section_item{
      padding: 20px 15px; margin-bottom: 15px;
      .bg_img{
        right: 15px; top: 20px; bottom: 20px; height: auto;
      }
      .name{
        font-size: 20px; margin-bottom: 10px;
      }
      .desc{
        font-size: 16px; line-height: 1.5;
      }
    }
  }
  #manufacturing_excellence_page {
    .video_container {
      padding-top: 30px; position: relative;
    }

    .main_section {
      .content_control_item {
        font-size: 14px; height: 55px; line-height: 1; display: flex; justify-content: center; align-items: center;
      }
      .content_section_item{
        padding-top: 45px; padding-bottom: 35px;
        .top_content{
          font-size: 16px; line-height: 1.5;
        }
        .slide_content{
          display: none; margin-top: 45px;
          &.mobile{
            display: block;
          }
        }
        .slide_title{
          font-size: 16px; line-height: 1.5
        }
        .slide_text{
          font-size: 16px; line-height: 1.5;
        }
      }
      .country_factory_section {
        .section_item{
          padding-top: 45px;
          .section_title{
            font-size: 25px; margin-bottom: 20px;
          }
          .duty{
            font-size: 16px; line-height: 1.5;
          }
          .factory_section {
            .item_content {
              img{
                margin-bottom: 10px;
              }
              .text{
                font-size: 18px; line-height: 1.3;
              }
              .desc{
                line-height: 1.3;
              }
            }
          }
        }
      }
    }
  }

  #culture_page {
    .main_section{
      padding-top: 35px; padding-bottom: 50px;
      .top_section {
        padding-bottom: 50px;
        .left_col{
          padding-right: 0;
          .title{
            font-size: 25px; line-height: 1.3; margin-bottom: 20px;
          }
          .text{
            font-size: 16px; line-height: 1.5; margin-bottom: 30px;
          }
        }
        .right_col{
          @include marginCenter(430);
          .video_content{
            margin-bottom: 8px;
          }
          .text{
            font-size: 12px;
          }
          .link{
            font-size: 12px;
          }
        }
      }
      .middle_section {
        .title{
          font-size: 25px;
        }
        .desc{
          font-size: 16px; line-height: 1.5; margin-bottom: 30px;
        }
        .image{
          width: 328px; height: 305px;
          .value_item{
            max-width: 175px;
          }
        }

      }

    }
  }
  #aboutus_page {
    .main_section {
      .top_section{
        padding-top: 35px; padding-bottom: 35px;
        .desc{
          text-align: left;
        }
      }
      .milestone_section {
        padding-bottom: 55px;
        .title{
          font-size: 25px; padding-top: 25px; padding-bottom: 20px;
        }
        .control {
          width: 100%; padding-top: 0; padding-right: 0; padding-left: 25px; padding-right: 25px; height: auto; padding-bottom: 35px;
          .years_swiper{
            .swiper-slide{
              font-size: 30px;
            }
          }
          .swiper-button-prev{
            left: 0 ; right: auto; transform: rotate(270deg); width: 25px; height: 25px; top: 8px;
          }
          .swiper-button-next{
            right: 0; left: auto; top: 0; bottom: auto; transform: rotate(90deg); width: 25px; height: 25px; top: 8px;
          }
        }
        .show_content{
          position: static; width: 100%; @include marginCenter(400);
          .text_content{
            font-size: 16px; line-height: 1.5; margin-bottom: 20px;
          }
          .swiper-slide{
            height: 300px;
          }
        }
      }
      .mission_section{
        padding-top: 40px; padding-bottom: 0;
        .section{
          margin-bottom: 0px; padding-bottom: 40px;
        }
        .title{
          font-size: 25px; margin-bottom: 30px;
        }
        .name{
          font-size: 20px; margin-bottom: 15px;
        }
        .content{
          font-size: 16px; line-height: 1.5;
        }
      }
    }
  }
  .services_page {
    .top_section {
      padding-bottom: 35px; padding-top: 35px;
      .left_col{
        padding-top: 0; font-size: 16px; line-height: 1.5; padding-bottom: 10px; padding-right: 0;
        .title{
          font-size: 20px;
        }
      }
      .right_col{
        @include marginCenter(400);
      }
    }
    .bottom_section{
      .content_container{
        position: relative;
      }
      .control_item {
        width: 120px; padding-left: 0; padding-right: 0px;
        .img_container{
          max-width: 55px;
        }
      }
      .arrow_btn{
        background-image: url("./../../images/arrow.svg"); width: 25px; height: 25px; position: absolute; background-size: cover; background-position: center;
        cursor: pointer;
      }
      .prev_btn{
        transform: rotate(270deg); left: 10px; top: 15px;
      }
      .next_btn{
        transform: rotate(90deg); right: 10px; top: 15px;
      }
      #services_control{
        @include marginCenter(600)
      }
      .left_col{
        margin-bottom: 20px;
      }
      .content_section {
        padding-bottom: 0;
        .content_item{
          padding-top: 35px; padding-bottom: 25px;
        }
        .title{
          font-size: 25px;
        }
        .text{
          font-size: 16px; line-height: 1.5;
        }
        .right_col {
          text-align: center;
          img{
            max-width: 400px; margin: 0 auto;
          }
        }
      }
    }
    .gallery_section {
      .gallery_trigger_link {
        img.zoomin{
          margin: auto;
        }
      }
    }
  }
  #sustainability_page {
    .top_section {
      padding-top: 35px; padding-bottom: 35px;
      .title{
        font-size: 25px;
      }
      .desc{
        font-size: 18px; line-height: 1.3;
      }
    }
    .middle_section {
      figure {
        figcaption{
          padding-left: 15px; padding-right: 15px;
        }
        h3{
          font-size: 21px;
        }
        p{
          font-size: 15px; width: 100%;
        }
      }
    }
    .report_section {
      padding-top: 30px;
      .section_title{
        font-size: 25px;
      }
      .section_image{
        height: 32px; margin-bottom: 30px;
      }
      .report_item {
        .report_name{
          font-size: 18px;
        }
        .report_date{
          font-size: 15px;
        }
        .download{
          height: 24px;
        }
      }
    }
  }
  #speed_page {
    .top_section {
      .left_col{
        padding-top: 0px; padding-left: 15px; padding-right: 15px;  padding-bottom: 30px;
      }
    }
    .right_col{
      .chart_text{
        font-size: 24px;
      }
      > svg{
        height: 350px;
      }
    }
  }
}



@media only screen and (min-width : 576px) and (max-width : 767px) {

}

@media only screen and (max-width : 575px) {

  #footer {
    padding-top: 40px; padding-bottom: 30px;
    .left_col {
      .address{
        margin-bottom: 20px;
      }
    }
    .right_col{
      text-align: left;
      .email{
        margin-bottom: 20px;
      }
      .social_media{
        margin-bottom: 30px;
      }
    }
  }
  .services_banner_section {
    .video_container {
      .video_content{
        height: auto;
      }
      .country {
        .country_item{
          padding-left: 0; padding-right: 0; margin-left: 15px; margin-right: 15px;
        }
      }
    }
  }
  #sales_merchandising_page {
    .main_section {
      .section_block{
        flex: 0 0 100%; max-width: 100%;
      }
      .middle{
        .block_content{
          padding-bottom: 20px;
        }
      }
    }
  }
  #design_service_page {
    .main_section {
      .middle_section {
        .content {
          .country {
            > div{
              padding-left: 130px; position: relative;
            }
            span{
              position: absolute; left: 0;
            }
          }
        }
      }
      .working_options_section {
        overflow: hidden;
        .control {
          margin-left: -25px; margin-right: -25px;
          .control_item{
            border-width: 15px; margin-left: 5%; margin-right: 5%; width: 35px; height: 35px;
          }
        }
        .control_content_item{
          padding: 20px 15px;
          .name{
            margin-bottom: 10px;
          }
          .desc{
            margin-bottom: 10px;
          }
          .bg_img{
            height: 240px;
          }
        }
      }
      .product_development_section {
        overflow: hidden; padding-bottom: 30px; padding-top: 25px;
        .title{
          text-align: left;
        }
        .control {
          margin-left: -25px; margin-right: -25px; margin-bottom: 35px;
          .control_item{
            border-width: 15px; margin-left: 5%; margin-right: 5%; width: 35px; height: 35px;
          }
        }
        .control_content_item {
          .text_content{
            padding: 0;
          }
          .video_content{
            height: auto; max-width: 100%;
          }
        }
      }
      .collection_section{
        padding-top: 25px;
        .mobile_img{
          height: 360px; max-width: 100%;
        }
      }
    }
  }
  #preproduct_office_page {
    .section_item {
      .text_content{
        width: 100%; max-width: 100%; min-height: 0; padding-right: 0;
      }
      .bg_img{
        position: static; height: 220px; width: 100%; max-width: 1000%; margin-top: 20px;
      }
    }
  }
  #manufacturing_excellence_page {
    .main_section {
      .country_factory_section {
        .section_item {
          .factory_section {
            .factory_section_item{
              margin-bottom: 35px;
              &:last-child{
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  .services_page{
    .bottom_section{
      #services_control {
        max-width: 360px;
      }
    }
  }
  #sustainability_page {
    .middle_section {
      figure {
        height: auto;
        figcaption{
          position: relative; background-color: $white_color; opacity: 1; padding-top: 20px; padding-bottom: 20px;
          h3{
            display: none;
          }
          p{
            color: $primary_color; font-size: 16px; line-height: 1.5;
          }
        }
        .img_bg{
          height: 375px;
        }
        &:before, &:after{
          display: none;
        }
      }

    }
    .report_section {
      .filter {
        .selectric-wrapper{
          display: block; margin-top: 5px;
        }
      }
    }
  }
  #aboutus_page {
    .main_section {
      .milestone_section {
        .show_content {
          .image_swiper{
            margin-left: -15px; margin-right: -15px;
          }
          .inner_swiper{
            padding-left: 20px; padding-right: 20px;
          }
        }
      }
    }
    .swiper-button-next, .swiper-button-prev{
      width: 12px; height: 24px; margin-top: -12px;
    }
    .swiper-button-next{
      right: -20px;
    }
    .swiper-button-prev{
      left: -20px;
    }
  }
}


@media only screen and (max-width : 320px) {

}